.c-card {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  transition: box-shadow 0.4s ease;
}
.c-card::before {
  content: '';
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  position: absolute;
  top: -8px;
  left: -8px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}
.c-card:hover::before {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}
.c-card__pic {
  width: 100%;
  height: 200px;
  background: #ddd;
  overflow: hidden;
  position: relative;
}
.c-card__pic img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.c-card__header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}
.c-card__header__star {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}
.c-card__header__star img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.c-card__header__star span:nth-of-type(1) {
  margin-right: 2px;
}
.c-card__header__star span:nth-of-type(2) {
  color: #888;
}
.c-card__header__number {
  color: #ff0045;
  margin-right: 8px;
}
.c-card__header__tags {
  position: relative;
  height: 22px;
}
.c-card__header__tags__txt {
  width: 64px;
  height: 22px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-card__header__tags__txt > div {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
.c-card__header__tags__txt img,
.c-card__header__tags__txt svg {
  width: inherit;
  height: inherit;
}
.c-card__header__tags__list {
  display: none;
}
.c-card__header__tags:hover .c-card__header__tags__list,
.c-card__header__tags:active .c-card__header__tags__list {
  max-width: 600px;
  max-height: 200px;
  padding: 4px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
  display: block;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  bottom: 22px;
  right: -16px;
}
.c-card__header__tags__list li {
}
.c-card__header__tags__list li p,
.c-card__header__tags__list li a {
  width: 100%;
  line-height: 32px;
  font-size: 13px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}
.c-card__ttl {
  height: 20px;
  font-size: 15px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-top: 8px;
}
.c-card__description {
  height: 56px;
  font-size: 12px;
  color: #888;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: 8px;
}
.c-card__bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}
.c-card__bottom__week {
  font-size: 12px;
  color: #888;
  margin-right: auto;
}
.c-card__bottom__price {
  font-size: 16px;
  font-weight: bold;
}
